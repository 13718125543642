import {useEffect, useState} from "react";

export const useCountDown = (initialValue: number = 59): [number, (value?: number) => void]  => {

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        if (value === 0) return
        const timerID = setInterval(() => {
            setValue(val => val - 1)
        }, 1000)
        return () => clearInterval(timerID)
    })

    const rebootInterval = (newValue: number = initialValue) => {
        setValue(newValue)
    }

    return [value, rebootInterval]
}