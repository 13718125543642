import React, {ChangeEventHandler, FC, useState} from 'react';
import {DeffaultPage} from "../App";
import Logo from "../logo.png";
import {SubmitHandler, useForm} from "react-hook-form";
import {useCountDown} from "../hooks/useCountDown";
import {usePatternPhone} from "../hooks/usePatternPhone";
import { host } from '../env';

interface ChangePasswordPageProps extends DeffaultPage {

}

interface FormValues {
    password: string
    acceptPassword: string
    phone: string,
    code: string
}

const ChangePasswordPage: FC<ChangePasswordPageProps> = ({onChangePage}) => {

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        setError,
        setValue
    } = useForm<FormValues>({
        defaultValues: {
            phone: '',
            code: '',
            password: '',
            acceptPassword: ''
        }
    })

    const validatePhone = usePatternPhone()

    const [successChangePassword, setSuccessChangePassword] = useState(false)
    const [isValidPhone, setIsValidPhone] = useState(false)

    const handleSubmitForm: SubmitHandler<FormValues> = async ({phone, code, password, acceptPassword}) => {
        if (password === "" && code === "" && acceptPassword === "") {
            try {
                const response = await fetch(host + '/code/reset', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({phone})
                })
                const result = await response.json()
                if (response.ok) {
                    setIsValidPhone(true)
                } else {
                    setError(result.field.toLowerCase(), {message: result.message})
                }
            } catch(error) {
                setError('phone', {message: 'Произошла ошибка, попробуйте позже'})
            }
            return;
        }
        if (password !== acceptPassword) {
            setError('password', {message: 'Пароли не совпадают!'})
            return;
        }
        try {
            const response = await fetch(host + '/Authentication/reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    phone,
                    code,
                    password
                })
            })

            const result = await response.json()

            if (response.ok) {
                setSuccessChangePassword(true)
                setTimeout(() => {
                    onChangePage('login')
                }, 4000)
            } else {
                setError(result.field.toLowerCase(), {message: result.message})
            }
        } catch(error) {
            setError('phone', {message: 'Произошла ошибка при отправке, попробуйте позже'})
        }
    }

    const onPhoneKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace' &&
            event.currentTarget.value.replace(/[^0-9]/g, '').length === 1) {
            setValue('phone', '')
        }
    }

    const handleChangeCode: ChangeEventHandler<HTMLInputElement> = (event) => {
        const newValue = event.currentTarget.value.replace(/[^0-9]/g , '')
        if (newValue.length > 4) {
            setValue('code', newValue.slice(0, 4))
            return;
        }
        setValue('code', newValue)
    }

    return (
        <div className='wrap'>
            <img className="logo" src={Logo} alt="logo"/>
            {successChangePassword ? <>
                <div className="success-animation">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
                <p className="success-notify">Пароль был успешно изменён</p>
            </> : <>
                <h1>Восстановление пароля</h1>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className="form-field">
                        <input type="text"
                               {...register('phone', {
                                   required: "Поле не может быть пустым!"
                               })}
                               placeholder="Введите телефон"
                               className={errors.phone ? 'form-input-error' : 'form-input'}
                               onChange={(event) => setValue('phone', validatePhone(event))}
                               onKeyDown={onPhoneKeyDown}
                               readOnly={isValidPhone}
                        />
                        {errors.phone && <span className='error-message'>{errors.phone.message}</span>}
                    </div>
                    {isValidPhone && <>
                        <div className="form-field">
                            <input type="text"
                                   {...register('code', {
                                       required: "Поле не может быть пустым!"
                                   })}
                                   placeholder="Введите последние 4 цифры входящего звонка"
                                   className={errors.code ? 'form-input-error' : 'form-input'}
                                   onChange={handleChangeCode}
                            />
                            {errors.code && <span className='error-message'>{errors.code.message}</span>}
                        </div>
                        <div className="form-field">
                            <input type="password"
                                   {...register('password', {
                                       required: "Поле не может быть пустым!",
                                       minLength: {
                                           value: 8,
                                           message: 'Пароль должен состоять минимум из 8 символов'
                                       },
                                       pattern: {
                                           value: /^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).*$/g,
                                           message: 'Пароль должен состоять из латинских букв и цифр'
                                       }
                                   })}
                                   placeholder="Введите новый пароль"
                                   className={errors.password ? 'form-input-error' : 'form-input'}
                            />
                            {errors.password && <span className='error-message'>{errors.password.message}</span>}
                        </div>
                        <div className="form-field">
                            <input type="password"
                                   {...register('acceptPassword', {
                                       required: "Поле не может быть пустым!",
                                       minLength: {
                                           value: 8,
                                           message: 'Пароль должен состоять минимум из 8 символов'
                                       },
                                       pattern: {
                                           value: /^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).*$/g,
                                           message: 'Пароль должен состоять из латинских букв и цифр'
                                       }
                                   })}
                                   placeholder="Повторите пароль"
                                   className={errors.acceptPassword ? 'form-input-error' : 'form-input'}
                            />
                            {errors.acceptPassword && <span className='error-message'>{errors.acceptPassword.message}</span>}
                        </div>
                    </>}
                    <button
                        type='submit'
                        className={`green-button`}
                    >{isValidPhone ? 'Сменить пароль' : 'Отправить'}</button>
                </form>
            </>}
        </div>
    );
};

export default ChangePasswordPage;