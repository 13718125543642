import React, {FC, useEffect, useState} from 'react';
import {AuthData, DeffaultPage} from "../App";
import {usePatternPhone} from "../hooks/usePatternPhone";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import CheckBoxedAutocomplete from "../components/CheckBoxedAutocomplete";
import Logo from '../logo.png'
import {host} from "../env";

interface RegistrationPageProps extends DeffaultPage {
    onChangeAuthData: (data: Partial<AuthData>) => void
    authData: AuthData
}

export interface Country {
    id: string
    name: string
}

type FormInputs = {
    Login: string
    FullName: string
    countries: Country[]
    Password: string
    passwordSecond: string
}

const RegistrationPage: FC<RegistrationPageProps> = ({onChangePage, onChangeAuthData, authData}) => {
    const [countries, setCountries] = useState<Country[]>([])
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        control,
        setError: setErrorForm
    } = useForm<FormInputs>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            countries: authData.Countries,
            Login: authData.Login,
            FullName: authData.FullName,
            Password: '',
            passwordSecond: ''
        }
    })

    useEffect(() => {
        const vniikrServer = document.getElementById("host-m.vniikr")?.innerText ?? 'https://m.vniikr.ru/s'
        fetch(`${vniikrServer}/mobile/registration/country`)
            .then(res => res.json())
            .then(countries => {
                setCountries(countries)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const validatePhone = usePatternPhone()

    const onPhoneKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace' &&
            event.currentTarget.value.replace(/[^0-9]/g, '').length === 1) {
            setValue('Login', '')
        }
    }

    const onSubmit: SubmitHandler<FormInputs> = async ({
                                                           Login,
                                                           FullName,
                                                           countries,
                                                           Password,
                                                           passwordSecond
                                                       }) => {

        if (Password !== passwordSecond) {
            setErrorForm('Password', {
                message: 'Пароли не совпадают'
            })
            setErrorForm('passwordSecond', {
                message: 'Пароли не совпадают'
            })
            return
        }

        onChangeAuthData({
            Login: Login,
            FullName: FullName,
            Countries: countries,
            Password: Password
        })
        setLoading(true)
        if (loading) return
        try {
            const response = await fetch(`${host}/code/accept-registration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    VerificationData: Login
                })
            })

            if (response.ok) {
                onChangePage('acceptRegister')
            } else {
                const error = await response.json()
                setLoading(false)
                setErrorForm("Login", {
                    type: "invalid_user",
                    message: error.message
                });
            }
        } catch (error) {
            setError('Что-то пошло не так, повторите попытку позже')
            console.error(error)
        }
    }

    return (
        <div className="wrap">
            <img className="logo" src={Logo} alt="logo"/>
            <h1>Регистрация</h1>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <p className="error-notify">{error}</p>
                <div className="form-field">
                    <input type="text"
                           {...register('Login', {
                               required: "Поле не может быть пустым!"
                           })}
                           placeholder="Введите телефон"
                           className={errors?.Login ? 'form-input-error' : 'form-input'}
                           onChange={(event) => {
                               setValue('Login', validatePhone(event))
                           }}
                           onKeyDown={onPhoneKeyDown}
                    />
                    {errors.Login?.type === 'required' && <span className='error-message'>{errors.Login.message}</span>}
                    {errors.Login?.type === 'invalid_user' &&
                        <span className='error-message'>{errors.Login.message}</span>}
                </div>

                <div className="form-field">
                    <input type="text"
                           {...register('FullName', {required: "Поле не может быть пустым!"})}
                           placeholder="Введите имя"
                           className={errors?.FullName ? 'form-input-error' : 'form-input'}
                    />
                    {errors.FullName?.type === 'required' &&
                        <span className='error-message'>{errors.FullName.message}</span>}
                    {errors.FullName?.type === 'invalid_user' &&
                        <span className='error-message'>{errors.FullName.message}</span>}
                </div>

                <div className="form-field">
                    <input type="password"
                           {...register('Password', {
                               required: "Поле не может быть пустым!",
                               minLength: {
                                   value: 8,
                                   message: 'Пароль должен состоять минимум из 8 символов'
                               },
                               pattern: {
                                   value: /^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).*$/g,
                                   message: 'Пароль должен состоять из латинских букв и цифр'
                               }
                           })}
                           placeholder="Введите пароль"
                           className={errors?.Password ? 'form-input-error' : 'form-input'}
                    />
                    {!errors.Password &&
                        <span className='info-message'>Пароль должен состоять из латинских букв и цифр, минимальная длина пароля 8 символов</span>}
                    {errors.Password && <span className='error-message'>{errors.Password.message}</span>}
                </div>

                <div className="form-field">
                    <input type="password"
                           {...register('passwordSecond', {
                               required: "Поле не может быть пустым!",
                               minLength: {
                                   value: 8,
                                   message: 'Пароль должен состоять минимум из 8 символов'
                               },
                               pattern: {
                                   value: /^(?=.*\d)(?=.*[a-zA-Z])(?!.*\s).*$/g,
                                   message: 'Пароль должен состоять из латинских букв и цифр'
                               }
                           })}
                           placeholder="Повторите пароль"
                           className={errors?.passwordSecond ? 'form-input-error' : 'form-input'}
                    />
                    {errors.passwordSecond && <span className='error-message'>{errors.passwordSecond.message}</span>}
                </div>

                <div className="form-field">
                    <Controller
                        name={'countries'}
                        control={control}
                        rules={{required: 'Поле не может быть пустым!'}}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <CheckBoxedAutocomplete error={error} value={value} onChange={onChange}
                                                    countries={countries}/>
                        )}
                    />
                </div>

                <button className="green-button" type="submit">Продолжить</button>
                <p className={'link-another-page'} onClick={() => {
                    onChangePage('login')
                }}>Уже есть аккаунт</p>
            </form>
        </div>
    );
};

export default RegistrationPage;