import React, {FC, useEffect, useState} from 'react';
import {AuthData, DeffaultPage} from "../App";
import {useCountDown} from "../hooks/useCountDown";
import Logo from '../logo.png'

interface AcceptRegisterPageProps extends DeffaultPage {
    authData: AuthData
}

const AcceptRegisterPage: FC<AcceptRegisterPageProps> = ({onChangePage, authData}) => {

    const [code, setCode] = useState('')
    const [interval, rebootInterval] = useCountDown(59)
    const [disableBtn, setDisableBtn] = useState(true)
    const [badCode, setBadCode] = useState(false)
    const [succesRegister, setSuccessRegister] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (interval === 0) {
            setDisableBtn(false)
        }
    }, [interval])

    useEffect(() => {
        const checkCode = async (code: string) => {
            const response = await fetch(`/Authentication/Registration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Login: authData.Login,
                    FullName: authData.FullName,
                    Password: authData.Password,
                    CountryIds: authData.Countries.map(country => country.id),
                    Code: code
                })
            })

            if (response.ok) {
                setSuccessRegister(true)
                setTimeout(() => {
                    onChangePage('login')
                }, 4000)
            } else {
                setBadCode(true)
            }
        }
        if (code.length === 4) {
             checkCode(code)
        }
    }, [code])

    const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value.replace(/[^0-9]/g , '')
        if (newValue.length > 4) {
            return
        }
        setCode(newValue)
    }

    const handleClickRepeatBtn = async () => {
        setCode('')
        setBadCode(false)
        setDisableBtn(true)
        rebootInterval()
        const response = await fetch(`/code/accept-registration`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                VerificationData: authData.Login
            })
        })

        if (response.ok) {
            setError(false)
        } else {
            setError(true)
        }
    }

    return (
        <div className="wrap">
            <img className="logo" src={Logo} alt="logo"/>
            {succesRegister ? <>
                <div className="success-animation">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
                <p className="success-notify">Регистрация прошла успешно</p>
            </> : <>
                <h1>Подтверждение</h1>
                <div className="form-field" style={{height: 'auto'}}>
                    {error && <p className="error-notify">Что-то пошло не так, повторите попытку позже</p>}
                    <input type="text"
                           value={code}
                           onChange={handleChangeCode}
                           placeholder="Введите последние 4 цифры входящего звонка"
                           className={badCode ? 'form-input-error' : 'form-input'}
                    />
                    {badCode  && <span className='error-message'>Введён неверный код!</span>}
                </div>
                <p className="form-subtext">Повторная отправка доступна через 00:{interval > 9 ? interval : '0' + interval} </p>
                <button className={`green-button ${disableBtn ? 'disable' : ''}`} disabled={disableBtn} onClick={handleClickRepeatBtn}>Отправить снова</button>
                <p className={'link-another-page'} onClick={() => {onChangePage('register')}}>Вернуться назад</p>
            </>}
        </div>
    );
};

export default AcceptRegisterPage;