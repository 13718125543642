import React, {FC, useEffect, useState} from 'react'
import Logo from '../logo.png'

import {SubmitHandler, useForm} from 'react-hook-form'

import './AuthPage.scss'
import {DeffaultPage} from "../App";
import {usePatternPhone} from "../hooks/usePatternPhone";
import {ErrorFieldModel} from "../interfaces/ErrorFieldModel";
import {host} from "../env";
import {useCountDown} from "../hooks/useCountDown";
import {IconButton} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {HELPERS} from "../constant/helper";
import Modal from '../components/Modal/Modal';

export type FormInputs = {
    UserName: string,
    Password: string,
    IsRemember: boolean
}

interface AuthPageProps extends DeffaultPage {
}

const AuthPage: FC<AuthPageProps> = ({onChangePage}) => {

    const [interval, rebootInterval] = useCountDown(0)
    const [blocked, setBlocked] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [helperModalText, setHelperModalText] = useState('')
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm<FormInputs>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            IsRemember: false
        }
    })

    useEffect(() => {
        if (interval === 0) {
            setBlocked(false)
        }
    }, [interval])

    const getQueryVariable = (variable: string) => {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    const validatePhone = usePatternPhone()

    const onPhoneKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace' &&
            event.currentTarget.value.replace(/[^0-9]/g, '').length === 1) {
            setValue('UserName', '')
        }
    }


    const onSubmit: SubmitHandler<FormInputs> = async ({UserName, Password, IsRemember}) => {
        const formData = new FormData()
        let returnUrl = getQueryVariable("ReturnUrl")

        formData.append('UserName', UserName)
        formData.append('Password', Password)
        formData.append('IsRemember', IsRemember.toString())
        formData.append('ReturnUrl', returnUrl as string);

        const tokenValidation = document.getElementById("token-validation")?.innerText ?? ' '
        const response = await fetch(`${host}/Authentication/Login`, {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': tokenValidation
            },
            body: formData
        })
        if (response.redirected) {
            window.location.href = response.url
        }

        if (response.ok) return

        const errors: ErrorFieldModel = await response.json();

        if (errors?.timeRemaining) {
            setBlocked(true)
            rebootInterval(errors.timeRemaining)
        } else {
            setError(errors.field, {
                type: 'invalid_user',
                message: errors.message
            });
        }
    }

    const handleClickHelp = (helpText: string) => {
        setIsOpen(true)
        setHelperModalText(helpText)
    }

    const handleClickCloseHelperModal = () => {
        setIsOpen(false)
        setHelperModalText('')
    }

    return (
        <div className="wrap">
            <img className="logo" src={Logo} alt="logo"/>
            <h1>Авторизация</h1>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-field">
                    <input type="text"
                           {...register('UserName', {required: "Поле не может быть пустым!"})}
                           placeholder="Введите телефон..."
                           className={errors.UserName ? 'form-input-error' : 'form-input'}
                           onChange={(event) => {
                               setValue('UserName', validatePhone(event))
                           }}
                           onKeyDown={onPhoneKeyDown}
                    />
                    {errors.UserName?.type === 'required' &&
                        <span className='error-message'>{errors.UserName.message}</span>}
                    {errors.UserName?.type === 'invalid_user' &&
                        <span className='error-message'>{errors.UserName.message}</span>}
                </div>

                <div className="form-field">
                    <input type="password"
                           {...register('Password', {required: "Поле не может быть пустым!"})}
                           placeholder="Введите пароль..."
                           className={errors.Password ? 'form-input-error' : 'form-input'}
                    />
                    {errors.Password?.type === 'required' &&
                        <span className='error-message'>{errors.Password.message}</span>}
                    {errors.Password?.type === 'invalid_user' &&
                        <span className='error-message'>{errors.Password.message}</span>}
                </div>

                <div className="form-field agree">
                    <input type="checkbox"
                           id="agree-checkbox"
                           {...register('IsRemember')}
                    />
                    <label htmlFor="agree-checkbox" className="label-checkbox">Запомнить меня</label>
                </div>
                {blocked &&
                    <p className="form-subtext">Аккаунт заблокирован, повторный вход возможен через {interval > 9 ? interval : '0' + interval} секунд(ы) </p>}
                <button className={`green-button ${blocked ? 'disable' : ''}`} disabled={blocked} type="submit">Войти</button>
                <div style={{width: '100%', display: 'flex',  justifyContent: 'space-between'}}>
                    <p className={'link-another-page'} onClick={() => {
                        onChangePage('register')
                    }}>Регистрация
                        <IconButton aria-label="help"
                                    style={{
                                        color: '#80808087',
                                        position: 'absolute',
                                        right: '-40px',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleClickHelp(HELPERS['register'])
                                    }}>
                            <HelpOutlineOutlinedIcon />
                        </IconButton>
                    </p>
                    <p className={'link-another-page'} onClick={() => onChangePage('changePassword')}>Восстановить пароль</p>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <a href={'https://m.vniikr.ru/support'} style={{fontFamily: 'TTNormRegular, sans-serif', fontSize: '16px', color: '#5f5ff8', textDecoration: 'none', margin: '0'}}>Техническая поддержка</a>
                    <IconButton aria-label="help"
                                style={{color: '#80808087'}}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    handleClickHelp(HELPERS['support'])
                                }}>
                        <HelpOutlineOutlinedIcon />
                    </IconButton>
                </div>
            </form>
            <Modal isOpen={isOpen} onClose={handleClickCloseHelperModal}>
                <p className="notification-message">{helperModalText}</p>
            </Modal>
        </div>
    );
};

export default AuthPage;