import * as React from 'react';
import {FC} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Popper from '@mui/material/Popper';
import {styled} from '@mui/material/styles';
import {Country} from "../pages/RegistrationPage";
import {FieldError} from "react-hook-form";
import {createFilterOptions, FilterOptionsState} from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon sx={{color: '#73c120'}} fontSize="small"/>;

interface CheckBoxedAutocompleteProps {
    countries: Country[],
    value: Country[],
    onChange: (value: Country[]) => void
    error: FieldError | undefined
}

const CssTextField = styled(TextField)({
    '&.MuiAutocomplete-inputRoot': {
      paddingRight: '0 !important'
    },
    '&.error': {
        '& .MuiOutlinedInput-root': {
            border: '1px solid red'
        }
    },
    '& label.Mui-focused': {
        color: '#73c120',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        minHeight: '50px',
        width: '347px',
        color: 'black',
        padding: '1px 5px',
        '& fieldset': {
            border: '1px solid transparent',
            boxShadow: '0px 4px 7px rgb(0 0 0 / 25%)',
            backgroundColor: 'white',
        },
        '&:hover fieldset': {
            border: '1px solid transparent',
            outline: 'none',
        },
        '&.Mui-focused fieldset': {
            outline: 'none',
            border: '1px solid #73c120'
        },
        '& .MuiOutlinedInput-input': {
            border: '1px solid transparent',
            boxShadow: 'none',
            backgroundColor: 'white',
            zIndex: '100',
            height: '30px'
        },
        '& input::placeholder': {
            color: 'grey',
            opacity: '1',
            fontFamily: 'TTNormRegular, sans-serif',
            fontSize: '18px'
        },
        '& .MuiButtonBase-root': {
            zIndex: '110'
        },
        '& .MuiChip-root.MuiChip-filled': {
            backgroundColor: '#73c120',
            color: 'white'
        }
    },
    '@media(min-width: 700px)': {
        '& .MuiOutlinedInput-root': {
            width: '422px',
        }
    },
})

const StyledPopper = styled(Popper)({
    borderRadius: '0px',
    padding: 0,
    '& .MuiPaper-root': {
        maxHeight: '300px',
        '& .MuiAutocomplete-noOptions': {
            display: 'none'
        }
    },
    '& ul': {
        maxHeight: '300px',
    },
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        borderRadius: '0px',
        '& ul': {
            padding: 0,
            margin: 0,
            borderRadius: '0px'
        },
        '& li': {
            border: '1px solid transparent',
            padding: 0,
            borderRadius: '0px'
        },
        '& li:last-child': {
            borderRadius: '0px'
        },
        '& li[aria-selected=true]': {
            backgroundColor: '#aef193'
        }
    },
});

const defaultFilterOptions = createFilterOptions();

const filterOptions = <T,>(options: T[], state: {inputValue: string}): T[] => {
    const {inputValue} = state
    if (!inputValue) return []
    return defaultFilterOptions(options as T[], state as FilterOptionsState<any>).slice(0, 10) as T[];
};

const CheckBoxedAutocomplete: FC<CheckBoxedAutocompleteProps> = ({countries, value, onChange, error}) => {

    return (
        <Autocomplete
            filterOptions={filterOptions}
            multiple={true}
            options={countries}
            disableCloseOnSelect
            disableClearable={true}
            disablePortal={true}
            getOptionLabel={(option) => option.name}
            PopperComponent={StyledPopper}
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => {
                onChange(value)
            }}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <>
                    <CssTextField {...params} className={error ? params.InputProps.className + ' error' : params.InputProps.className} placeholder="Страны"/>
                    {error?.type === 'required' && <span className='error-message'>{error?.message}</span>}
                </>
            )}
        />
    );
}

export default CheckBoxedAutocomplete;