import React, {useCallback, useState} from 'react';
import AuthPage from "./pages/AuthPage";
import RegistrationPage, {Country} from "./pages/RegistrationPage";
import AcceptRegisterPage from "./pages/AcceptRegisterPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";

export type pageType = 'login' | 'register' | 'acceptRegister' | 'changePassword'

export interface AuthData {
    Login: string
    FullName: string
    Password: string
    Code: string
    Countries: Country[]
}

export interface DeffaultPage {
    onChangePage: (page: pageType) => void
}

function App() {

    const [page, setPage] = useState<pageType>('login')
    const [authData, setAuthData] = useState<AuthData>({
        Login: '',
        FullName: '',
        Password: '',
        Countries: [],
        Code: ''
    })

    const changePage = useCallback((page: pageType) => {
        setPage(page);
    }, [])

    const changeAuthData = (data: Partial<AuthData>) => {
        setAuthData(prevState => ({
            ...prevState,
            ...data
        }))
    }

    if (page === 'login') {
        return <AuthPage onChangePage={changePage}/>
    }

    if (page === 'register') {
        return <RegistrationPage authData={authData} onChangeAuthData={changeAuthData} onChangePage={changePage}/>
    }

    if (page === 'acceptRegister') {
        return <AcceptRegisterPage onChangePage={changePage} authData={authData}/>
    }

    if (page === 'changePassword') {
        return <ChangePasswordPage onChangePage={changePage}/>
    }

    return (
        <></>
    );
}

export default App;
